import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LogoFooterComponent } from 'src/app/shared/components/logo-footer/logo-footer.component';

@Component({
	selector: 'app-root',
	standalone: true,
	imports: [RouterOutlet, LogoFooterComponent],
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	constructor() { }

	ngOnInit(): void {}
}
