import { provideHttpClient } from '@angular/common/http';
import {
	ApplicationConfig,
	importProvidersFrom,
	provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import { provideRouter, withComponentInputBinding, withHashLocation } from '@angular/router';
import { routes } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

export const appConfig: ApplicationConfig = {
	providers: [
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		provideHttpClient(),
		provideRouter(routes, withHashLocation(), withComponentInputBinding()),
		provideExperimentalZonelessChangeDetection(),
		importProvidersFrom([BrowserAnimationsModule])
	],
};
